import { parseTimetoken } from "#app/lib/converter.ts";
import { parseIfString, sliceName } from "#app/lib/helper.ts";
import { type loader as RootLoader } from "#app/root";
import { deleteCache, getCache, setCache } from "#app/utils/cache-client.ts";
import { useMatchesIndex } from "#app/utils/matches.ts";
import { initPubnub } from "#app/utils/pubnub.ts";
import { UserBasic } from "#app/utils/validation/auth-validation.ts";
import {
	useFetcher,
	useLocation,
	useParams,
	useRevalidator,
	useRouteLoaderData,
} from "@remix-run/react";
import React from "react";
import { toast as showToast } from "sonner";

{
	/*{
    //user,
    //setNotifCall,
    //setIsTypingCall,
    //setNotifDeleteCall,
    //setNotifReloadCall,
  }*/
}

type SignalMessage = {
	actualChannel: string;
	publisher: string;
	message: any;
	timetoken: number;
	messageType?: string | number | undefined;
	uuid?: string | undefined;
};

let typingTimer: NodeJS.Timeout;

export const PubNubMain = React.memo(() => {
	const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
	if (!user) return;
	const pubnub = initPubnub(user);
	const params = useParams();
	const fetcher = useFetcher();
	const fetcherList = useFetcher({ key: "list-chat" });

	const fetcherMessages = useFetcher({
		key: params?.roomId,
	});

	const match = useMatchesIndex(2);

	async function localUser(user: UserBasic) {
		const basicUserKey = "user-data-basic";
		const userBasic = await getCache(basicUserKey);
		const { publishKey, subscribeKey, ...basicData } = user;
		if (userBasic?.id !== user.id) {
			await setCache(basicUserKey, basicData);
		} else {
			await setCache(basicUserKey, basicData);
		}
	}

	React.useEffect(() => {
		// Periksa jika `user` valid

		// Langganan ke saluran dan simpan fungsi `unsubscribe`
		const unsubscribe = initPubnubClient();

		// Fungsi pembersihan dipanggil saat komponen di-unmount
		return () => {
			// Panggil fungsi `unsubscribe` untuk menghentikan langganan
			unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Tambahkan `user` ke array ketergantungan jika relevan

	const initPubnubClient = () => {
		// Implementasi langganan ke saluran
		console.log("Subscribed to channels");
		// fetcher.load('/api/pubnub-store');
		localUser(user);
		subscribeChannels();

		// Kembalikan fungsi `unsubscribe` untuk menghentikan langganan
		return () => {
			console.log("Unsubscribed from channels");
			unsubscribeChannels(subscribeChannels());
		};
	};

	const unsubscribeChannels = (listener) => {
		pubnub.removeListener(listener);
		pubnub.unsubscribe({
			channels: ["private.*", "group.*", "chat-group.*"],
		});
	};

	const subscribeChannels = async () => {
		pubnub.subscribe({
			channels: ["private.*", "group.*", "chat-group.*"],
			withPresence: true,
		});

		// const userUUID = await pubnub.objects.getUUIDMetadata();

		const listener = {
			signal(sig) {
				if (sig?.message === "delete_all") {
				} else if (sig?.message === "reload_msg") {
				} else {
					if (match) {
						if (
							sig.publisher !== user.uuid &&
							sig?.channel ===
								(match?.data as { roomId: { channel: string } })?.roomId
									?.channel
						) {
							const container = document.getElementById(
								"container-typing-indicator",
							);
							if (container instanceof HTMLDivElement) {
								if (sig.message === "typing_off") {
									container.style.display = "none";
								} else {
									container.style.display = "flex";
								}

								const typingIndicator =
									container.querySelector("#typing-indicator");
								if (typingIndicator instanceof HTMLDivElement) {
									typingIndicator.innerHTML = sig.message;
								}
							}
						}
					}
				}
			},
			async message(msg: SignalMessage) {
				console.warn("DEBUGPRINT[5]: pubnub-main.tsx:138: msg=", msg);
				const waktu = msg.timetoken / 1e4;
				const notificationTime = new Date(waktu);
				const timeToast = notificationTime.toISOString();

				const msgText = sliceName(msg?.message?.pn_gcm?.notification?.body);
				const msgAuthor = msg?.message?.pn_gcm?.notification?.title;
				// const toast = {
				// 	id: msg.timetoken.toString(),
				// 	type: "message" as "message",
				// 	description: `${msgAuthor} : ${msgText}`,
				// 	title: "New message ",
				// };

				async function replaceText(data, user, fetcherList, fetcherMessages) {
					let room = data?.channel ?? data?.actualChannel;
					let message = data.message;

					const custom_data = parseIfString(message?.pn_gcm?.data?.custom_data);

					const firstName =
						custom_data?.author?.firstName ?? message?.author?.firstName ?? "";
					const lastName =
						custom_data?.author?.lastName ?? message?.author?.lastName ?? "";

					const fullName = lastName ? `${firstName} ${lastName}` : firstName;

					const metadata = message?.metadata;

					const chatCategory = metadata?.category;

					const isAdminSystem =
						chatCategory === "created_zoom" ||
						chatCategory === "removed_zoom" ||
						chatCategory === "created_group" ||
						chatCategory === "add_member" ||
						chatCategory === "leave_member" ||
						chatCategory === "removed_member";

					let parts = room?.split(".");
					let firstWord = parts ? parts[0] : null;
					let sent_to;
					let type_msg = message?.pn_apns?.aps?.alert?.body ?? "sent a message";

					switch (firstWord) {
						case "group":
							sent_to = type_msg + " in class chat";
							break;
						case "private":
							sent_to = type_msg;
							break;
						case "chat-group":
							sent_to = type_msg + " in group chat";
							break;
						default:
							sent_to = type_msg;
					}

					const cacheListKey = `user-data-list-chat-${user.id}`;
					const cacheListChat = (await getCache(cacheListKey)) as {
						data: { archive: any[]; class: any[]; chats: any[] };
					};
					const cacheMessagesKey = `data-messages-${msg.actualChannel}-true`;
					const cacheMessages = (await getCache(cacheMessagesKey)) as {};

					const newMessage = {
						channel: msg.actualChannel,
						timetoken: msg.timetoken,
						message: msg.message,
						messageType: -1,
						uuid: msg.publisher,
					};

					if (cacheMessages) {
						const cachedData = cacheMessages?.messages;
						const newCacheData = [...(cachedData ?? []), newMessage];
						const data = {
							...cacheMessages,
							messages: newCacheData,
						};
						await setCache(cacheMessagesKey, data);

						fetcherMessages.submit(
							{ channel: msg.actualChannel },
							{ method: "get", action: "/resources/messages" },
						);
					}

					if (cacheListChat) {
						async function assignMessageToAll(
							data: any,
							channel: string,
							msgText: string,
							timetoken: string,
						) {
							const types = ["archive", "chats", "class"] as const;
							let found = false;

							for (const type of types) {
								const index = data[type].findIndex(
									(item: any) => item.channel === channel,
								);

								if (index !== -1) {
									// Assign `message` jika item ditemukan
									data[type][index].message = msgText;
									data[type][index].timetoken = timetoken;
									if (msg.publisher !== user?.uuid) {
										data[type][index].unread = data[type][index].unread + 1;
									}

									data[type] = data[type]
										.map((item: any) => ({
											...item,
											sortTime: item.timetoken
												? (parseTimetoken(item.timetoken) as string)
												: (item.createdDate as string),
										}))
										.sort(
											(a, b) =>
												(new Date(b.sortTime).getTime() as number) -
												(new Date(a.sortTime).getTime() as number),
										);

									found = true;

									const newData = { data: data };
									await setCache(cacheListKey, newData);

									break; // Keluar dari loop setelah menemukan dan meng-assign message
								}
							}

							if (!found) {
								await deleteCache(cacheListKey);
								console.log(
									`Channel ${channel} tidak ditemukan di semua tipe, cache dihapus.`,
								);
							}

							fetcherList.submit(
								{ userId: user?.id as string },
								{ method: "get", action: "/resources/channels" },
							);
						}

						const cachedData = cacheListChat.data;
						const messageText =
							custom_data?.text ||
							custom_data?.name ||
							message?.metadata?.title;

						assignMessageToAll(
							cachedData,
							msg.actualChannel,
							messageText,
							msg.timetoken.toString(),
						);
					} else {
						fetcherList.submit(
							{ userId: user?.id },
							{ method: "get", action: "/resources/channels" },
						);
					}

					if (data.publisher !== user.uuid) {
						setTimeout(() => {
							showToast.custom((t) => (
								<div
									className={`max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex`}
								>
									<div className="flex flex-1 items-center  rounded-lg px-3 py-1 cursor-pointer">
										<div className="relative w-14 h-14 flex flex-shrink-0 items-end">
											<img
												width={60}
												height={60}
												src={
													custom_data?.author?.imageUrl ||
													`/static/images/logokedi.svg`
												}
												className="absolute inset-0 h-full w-full object-cover rounded-xl shadow-md border border-gray-300"
												alt=""
											/>
											<span className="absolute h-4 w-4 bg-green-400 rounded-full bottom-0 right-0 border-2 border-white" />
										</div>
										<div className="ml-3">
											<span className="font-semibold tracking-tight text-xs">
												{fullName}
											</span>{" "}
											<span className="text-xs leading-none opacity-50">
												{sent_to}
											</span>
											<p className="text-[13px] leading-4 pt-2 italic opacity-70">
												{" "}
												{custom_data?.text ||
													custom_data?.name ||
													message?.metadata?.title}{" "}
												{isAdminSystem ? message?.text : ""}
											</p>
											<span className="text-[10px] text-blue-500 font-medium leading-4 opacity-75">
												a few seconds ago
											</span>
										</div>
									</div>
									<div className="flex border-l border-gray-200">
										<button
											onClick={() => {
												showToast.dismiss(t);
											}}
											className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth={1.5}
												stroke="currentColor"
												className="size-6"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M6 18 18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
							));
						}, 0);
					}
				}

				replaceText(msg, user, fetcherList, fetcherMessages);

				if (match) {
					typingTimer = setTimeout(() => {
						// fetcherList.submit(
						// 	{ userId: user?.uuid },
						// 	{ method: "get", action: "/resources/channels" },
						// );
						// fetcherMessages.submit(
						// 	{ channel: msg.actualChannel, invalidate: "true" },
						// 	{ method: "get", action: "/resources/messages" },
						// );
					}, 100);
				}

				//
				// const payload = {
				// 	intent: "new-message",
				// 	message: JSON.stringify(message),
				// };
				//
				// fetcher.submit(payload, {
				// 	method: "post",
				// 	action: match?.pathname,
				// });
				//console.log(msg);
				// if (msg.publisher !== user.uuid) {
				// 	revalidator.revalidate();
				// }
				//setNotifCall(msg);
			},
		};
		pubnub.addListener(listener);
		return listener;
	};

	return <div className="hidden">PUBNUB</div>;
});
